import { render, staticRenderFns } from "./m-resource-links-viewer.vue?vue&type=template&id=b2165f5e&scoped=true&"
import script from "./m-resource-links-viewer.vue?vue&type=script&lang=ts&"
export * from "./m-resource-links-viewer.vue?vue&type=script&lang=ts&"
import style0 from "./m-resource-links-viewer.vue?vue&type=style&index=0&id=b2165f5e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2165f5e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCheckbox,VContainer,VDataTable,VIcon})
