































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import { defineComponent, ref, computed } from '@vue/composition-api';
// import axios from 'axios';
import MongoDoc from './types';

export default defineComponent({
  name: 'ResourceLinks',
  props: {
    value: {
      required: true,
      type: Object as () => MongoDoc
    },
    adkType: {
      type: String,
      default: ''
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    isUpdating: {
      type: Boolean,
      default: false
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const header = [
      {
        text: 'Status',
        align: 'center',
        value: 'click',
        sortable: false,
        width: '10%'
      },
      {
        text: 'Research Item',
        align: 'start',
        value: 'resource',
        width: '55%'
      },
      {
        text: 'Required',
        align: 'center',
        value: 'required',
        sortable: false,
        width: '10%'
      },
      {
        text: 'Review',
        value: 'cta',
        align: 'center',
        sortable: false,
        width: '15%'
      },
      {
        text: 'Completed?',
        align: 'center',
        value: 'finish',
        sortable: false,
        width: '10%'
      }
    ];

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });

    const fieldValue = computed({
      get: () => adkData.value?.fields?.find(f => f.name === 'metagrabber')?.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const fieldSetup = computed(() => {
      return adkData.value?.fields?.find(f => f.name === 'metagrabber')?.setup;
    });

    const isComplete = computed(() => {
      return fieldValue.value?.researchProgress?.every(
        (item: any) => !item.required || (item.viewed && item.completed)
      );
    });
    const isLoading = ref(false);

    // async function fetchFormData() {
    //   const API_ENDPOINT = process.env.VUE_APP_RESEARCHADK_NEW_BADGE;
    //   const studentId = props.studentDoc?._id.toString();
    //   try {
    //     isLoading.value = true;
    //     const res = await axios.post(
    //       `${API_ENDPOINT}?program_id=${ctx.root.$route.params.programId}&student_id=${studentId}`,
    //       {
    //         headers: {
    //           'Content-Type': 'application/json'
    //         }
    //       }
    //     );
    //     if (res) {
    //       isLoading.value = false;
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
    // fetchFormData();

    function goToLink(index) {
      fieldValue.value.researchProgress[index].viewed = true;
      ctx.emit('save-progress');
      // if (link[0].newCandidate) {
      //   link[0].newCandidate = false;
      // }
    }

    return {
      isComplete,
      fieldSetup,
      isLoading,
      fieldValue,
      goToLink,
      header
    };
  }
});
