var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"module-research pa-0",staticStyle:{"position":"relative"}},[_c('v-data-table',{staticClass:"module-research__data-table research-default-data",attrs:{"headers":_vm.header,"items":_vm.isPreview
        ? (_vm.fieldSetup && _vm.fieldSetup.researchLinks) || []
        : (_vm.fieldValue && _vm.fieldValue.researchProgress) || [],"items-per-page":100,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.click",fn:function(ref){
        var item = ref.item;
return [(item.completed)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-checkbox-marked-circle ")]):(item.viewed)?_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v(" mdi-alert-circle ")]):_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-close-circle ")])]}},{key:"item.resource",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.resource)+" ")]}},{key:"item.cta",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(item.viewed)?_c('v-btn',{attrs:{"disabled":_vm.isPreview,"href":item.link,"x-small":"","outlined":"","depressed":"","target":"_blank"}},[_c('v-icon',{attrs:{"left":"","x-small":"","color":"green"}},[_vm._v("mdi-check-bold ")]),_vm._v("Goto Link")],1):_c('v-btn',{attrs:{"x-small":"","href":item.link,"outlined":"","depressed":"","target":"_blank"},on:{"click":function($event){return _vm.goToLink(index)}}},[_vm._v(" Goto Link ")])]}},{key:"item.required",fn:function(ref){
        var item = ref.item;
return [(item.required)?_c('v-btn',{attrs:{"x-small":"","color":"red","outlined":"","depressed":""}},[_vm._v("Required")]):_c('v-btn',{attrs:{"depressed":"","x-small":""}},[_vm._v("Recommended")])]}},{key:"item.finish",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":!item.viewed,"type":"checkbox"},model:{value:(item.completed),callback:function ($$v) {_vm.$set(item, "completed", $$v)},expression:"item.completed"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }